import { StorageKeys } from '../constants/constants';
import SmgToken from '../models/smg-token';
import Cookies from 'universal-cookie';

function getMsSsoToken(): string {
  return getCookie(StorageKeys.msTeamsToken) as string;
}

function setMsSsoToken(msSsoToken: string) {
  setCookie(StorageKeys.msTeamsToken, msSsoToken);
}

function removeMsSsoToken(): void {
  removeCookie(StorageKeys.msTeamsToken);
}

function getSmgToken(): SmgToken {
  let smgAccessToken = getCookie(StorageKeys.smgAccessToken);
  let smgRefreshToken = getCookie(StorageKeys.smgRefreshToken);
  return {
    accessToken: smgAccessToken,
    refreshToken: smgRefreshToken,
  };
}

function getSmgAccessToken(): string {
  return getCookie(StorageKeys.smgAccessToken);
}

function getSmgRefreshToken(): string {
  return getCookie(StorageKeys.smgRefreshToken);
}

function setSmgToken(smgToken: SmgToken) {
  setCookie(StorageKeys.smgAccessToken, smgToken.accessToken);
  setCookie(StorageKeys.smgRefreshToken, smgToken.refreshToken);
}

function removeSmgToken() {
  removeCookie(StorageKeys.smgAccessToken);
  removeCookie(StorageKeys.smgRefreshToken);
}

function getGrantMsgDisply(): string {
  return getCookie(StorageKeys.grantMsgDisplay);
}

function setGrantMsgDisply() {
  setCookie(StorageKeys.grantMsgDisplay, true);
}

function removeGrantMsgDisply() {
  removeCookie(StorageKeys.grantMsgDisplay);
}

function saveRealm(realm: string | null) {
  if (realm) {
    setCookie(StorageKeys.realm, realm);
  }
}

function getRealm(): string {
  return getCookie(StorageKeys.realm) as string;
}

function saveClient(client: string | null) {
  if (client) {
    setCookie(StorageKeys.client, client);
  }
}

function getClient(): string {
  return getCookie(StorageKeys.client) as string;
}

function saveAuthBase(authBase: string | null) {
  if (authBase) {
    setCookie(StorageKeys.smgAuthBase, authBase);
  }
}

function getAuthBase(): string {
  return getCookie(StorageKeys.smgAuthBase) as string;
}

function saveContactUrl(contactUrl: string | null) {
  if (contactUrl) {
    setCookie(StorageKeys.contactUrl, contactUrl);
  }
}

function getContactUrl(): string {
  return getCookie(StorageKeys.contactUrl) as string;
}

function setCookie(cookieName: string, cookieValue: any) {
  const cookies = new Cookies();
  cookies.set(cookieName, cookieValue, {
    secure: true, sameSite: 'none', path: '/', expires: new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 365) });
}

function getCookie(cookieName: string) {
  const cookies = new Cookies();
  return cookies.get(cookieName);
}

function removeCookie(cookieName: string) {
  const cookies = new Cookies();
  return cookies.remove(cookieName, {secure: true, sameSite: 'none', path: '/' });
}

export {
  getMsSsoToken,
  setMsSsoToken,
  removeMsSsoToken,
  getSmgToken,
  getSmgAccessToken,
  getSmgRefreshToken,
  setSmgToken,
  removeSmgToken,
  saveRealm,
  getRealm,
  saveClient,
  getClient,
  saveAuthBase,
  getAuthBase,
  saveContactUrl,
  getContactUrl,
  getGrantMsgDisply,
  setGrantMsgDisply,
  removeGrantMsgDisply,
};

enum CommunicationLogType {
  OUTGOING_CALL,
  MISSED_CALL,
  INCOMING_CALL,
  VOICEMAIL,
  RECORDING,
  FAX,
}

export default CommunicationLogType;

enum AliasType {
  ANONYMOUS, //
  ACD,
  ACD_LIGHT,
  ATTENDANT,
  SWITCHBOARD,
  OFFICE_FIXED,
  PRIVATE_FIXED,
  OFFICE_MOBILE,
  PRIVATE_MOBILE,
}
export default AliasType;

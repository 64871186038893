import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const settingsDataRecord = (state: any) => state.settingsData;

const hasMsGraphAccessSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasMsGraphAccess;
});

const hasSearchContactsAccessSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasSearchContactsAccess;
});

const addPermissionsUrlSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.addPermissionsUrl;
});

const addSearchPermissionsUrlSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.addSearchPermissionsUrl;
});

const loggedInAsSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.loggedInAs;
});

const isGlobalAdminSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.isGlobalAdmin;
});

const isAdminSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.isAdmin;
});

const hasSmsWidgetAccessSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasSmsWidgetAccess;
});

const hasCallRecordingWidgetAccessSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasCallRecordingWidgetAccess;
});

const hasCallTransferWidgetAccessSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasCallTransferWidgetAccess;
});

const presenceActivitiesSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.presenceActivities;
});

const userPresenceMappingsSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.userPresenceMappings;
});

const userPresenceDefaultMappingsSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.userPresenceDefaultMappings;
});

const voicemailSettingsSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.voicemailSettings;
});

const isPermissionsLoadedSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.isPermissionsLoaded;
});

const testUsersSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.testUsers;
});

const smsMaxLengthSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.smsMaxLength;
});

const featureFlagsSelector = createSelector(settingsDataRecord, (record = EMPTY_OBJECT) => {
  return record.featureFlags;
});

export {
  hasMsGraphAccessSelector,
  hasSearchContactsAccessSelector,
  addPermissionsUrlSelector,
  addSearchPermissionsUrlSelector,
  loggedInAsSelector,
  presenceActivitiesSelector,
  userPresenceMappingsSelector,
  isGlobalAdminSelector,
  isAdminSelector,
  hasSmsWidgetAccessSelector,
  hasCallTransferWidgetAccessSelector,
  hasCallRecordingWidgetAccessSelector,
  userPresenceDefaultMappingsSelector,
  isPermissionsLoadedSelector,
  testUsersSelector,
  voicemailSettingsSelector,
  smsMaxLengthSelector,
  featureFlagsSelector,
};

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
});

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
);

export default function configureStore() {
  const store = createStore(rootReducer, enhancer);
  return store;
}

import { combineReducers } from 'redux';
import authenticateReducer from './authenticate-reducers';
import commonReducer from './common-reducers';
import userDataReducer from './user-data-reducers';
import settingsDataReducer from './settings-data-reducers';
import voicemailReducer from './voicemail-reducers';
import callReducer from './call-reducers';
import deviceReducer from './device-reducers';

export default combineReducers({
  authData: authenticateReducer,
  common: commonReducer,
  userData: userDataReducer,
  voicemail: voicemailReducer,
  settingsData: settingsDataReducer,
  call: callReducer,
  device: deviceReducer,
});

import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authUrlSelector } from '../store/selectors/authentication-selectors';

interface AuthStartPageProps {
  loginUrl: string;
}

interface AuthStartState {}

class AuthStartPage extends Component<AuthStartPageProps, AuthStartState> {
  render() {
    if (this.props.loginUrl) {
      window.location.assign(this.props.loginUrl);
    }
    return <div></div>;
  }
}

// eslint-disable-next-line
const mapStateToProps = (state: any) => ({
  loginUrl: authUrlSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthStartPage);

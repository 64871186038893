import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const commonDataRecord = (state: any) => {
  return state.common;
};

const msTeamsContextSelector = createSelector(commonDataRecord, (record = EMPTY_OBJECT) => {
  return record.msTeamsContext;
});

const isLoadingSelector = createSelector(commonDataRecord, (record = EMPTY_OBJECT) => {
  return record.loadingCounter > 0;
});

export { msTeamsContextSelector, isLoadingSelector };

class PresenceActivity {
  id: string;
  available: boolean;
  name: string;
  stateName: string; //Swedish name

  constructor(id: string, available: boolean, name: string, stateName: string) {
    this.id = id;
    this.available = available;
    this.name = name;
    this.stateName = stateName;
  }
}

export default PresenceActivity;

import { Button, Text, Subtitle2Stronger, Link } from '@fluentui/react-components';
import { ArrowSync16Filled } from '@fluentui/react-icons';
import * as microsoftTeams from '@microsoft/teams-js';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { grantPermission } from '../../i18n/all';
import * as settingsDataActions from '../../store/actions/settings-data-action';
import { isLoadingSelector, msTeamsContextSelector } from '../../store/selectors/common-selectors';
import {
  addPermissionsUrlSelector,
  addSearchPermissionsUrlSelector,
  hasMsGraphAccessSelector,
  hasSearchContactsAccessSelector,
  hasSmsWidgetAccessSelector,
  isGlobalAdminSelector,
} from '../../store/selectors/settings-data-selectors';
import * as storageHelper from '../../helpers/storage-helper';

interface MsGrantPermissionsProps {
  msTeamsContext: microsoftTeams.app.Context | null;
  addPermissionsUrl: string;
  addSearchPermissionsUrl: string;
  hasMsGraphAccess: boolean;
  hasSearchContactsAccess: boolean;
  hasSmsWidgetAccess: boolean;
  isGlobalAdmin: boolean;
  isLoading: boolean;
  actions: any;
}

interface MsGrantPermissionsState {}

export class MsGrantPermissions extends React.Component<MsGrantPermissionsProps, MsGrantPermissionsState> {
  constructor(props: MsGrantPermissionsProps) {
    super(props);
    this.state = {};
  }

  render() {
    const start = grantPermission.getPermission.indexOf('[');
    const end = grantPermission.getPermission.indexOf(']');
    const startU = grantPermission.getUserPermission.indexOf('[');
    const endU = grantPermission.getUserPermission.indexOf(']');
    if (!!this.props.hasMsGraphAccess)
      storageHelper.setGrantMsgDisply();
    if (!this.props.hasMsGraphAccess && !!storageHelper.getSmgToken().accessToken)
      storageHelper.removeGrantMsgDisply();
      return (
        <>
          <div>
            <Subtitle2Stronger>{grantPermission.header}</Subtitle2Stronger>
            <Button
              size="medium"
              icon={<ArrowSync16Filled />}
              id="refreshPermissions"
              onClick={() => {
                window.location.reload();
              }}
              style={{ float: 'right' }}
            />
          </div>
          {!!this.props.isGlobalAdmin && !!this.props.hasMsGraphAccess && !this.props.isLoading && (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <Text align="center" id="grantedInfo">
                {grantPermission.permissionsGranted}
              </Text>
            </div>
          )}
          {(!this.props.hasMsGraphAccess || (!this.props.hasSearchContactsAccess && this.props.hasSmsWidgetAccess)) &&
            !this.props.isLoading && (
              <div>
                {!this.props.hasMsGraphAccess && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <Text align="center">{grantPermission.getPermission.substr(0, start)}&nbsp;</Text>
                    {/* eslint-disable-next-line */}
                    <Link href={this.props.addPermissionsUrl} target="_blank" id="grandPermissionUrl">
                      {grantPermission.getPermission.substr(start + 1, end - 1 - start)}
                    </Link>
                    <Text align="center">&nbsp;{grantPermission.getPermission.substr(end + 1)}</Text>
                  </div>
                )}
                {!this.props.hasSearchContactsAccess && this.props.hasSmsWidgetAccess && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <Text align="center">{grantPermission.getUserPermission.substr(0, startU)}&nbsp;</Text>
                    {/* eslint-disable-next-line */}
                    <Link href={this.props.addSearchPermissionsUrl} target="_blank" id="grandUserPermissionUrl">
                      {grantPermission.getUserPermission.substr(startU + 1, endU - 1 - startU)}
                    </Link>
                    <Text align="center">&nbsp;{grantPermission.getUserPermission.substr(endU + 1)}</Text>
                  </div>
                )}
              </div>
            )}
          {!!this.props.hasSearchContactsAccess && (
            <Text id="grantedUserInfo" style={{ textAlign: 'center' }}>
              {grantPermission.permissionsSearchGranted}
            </Text>
          )}
        </>
      );
  }
}

const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  addPermissionsUrl: addPermissionsUrlSelector(state),
  addSearchPermissionsUrl: addSearchPermissionsUrlSelector(state),
  hasMsGraphAccess: hasMsGraphAccessSelector(state),
  hasSearchContactsAccess: hasSearchContactsAccessSelector(state),
  hasSmsWidgetAccess: hasSmsWidgetAccessSelector(state),
  isGlobalAdmin: isGlobalAdminSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MsGrantPermissions);

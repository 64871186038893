import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = (jwtToken: string): boolean => {
  if (!jwtToken) {
    return false;
  }

  const sec = (jwtDecode(jwtToken) as any).exp as number;
  var isExpired = Date.now() >= sec * 1000;
  return isExpired;
};

import * as actionCreators from '../action-creators/voicemail-creator';
import * as commonActionCreators from '../action-creators/common-action-creators';
import httpStatusCodes from '../../constants/http-status-codes';
import { BaseAction } from '../model/action';
import { getVoicemails, deleteVoicemails, updateVoicemailSeenPlayed } from '../../api/voicemailApi';
import { HistoryItem } from '../../models/communication-log';

const loadVoicemails = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const voicemailResponse = await getVoicemails();
    dispatch(actionCreators.acSetVoicemails(voicemailResponse.data.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const removeVoicemails = (ids: number[]) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await deleteVoicemails(ids);
    if (response.status === httpStatusCodes.OK) {
      const refreshResponse = await getVoicemails();
      dispatch(actionCreators.acSetVoicemails(refreshResponse.data.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const updateVoicemailState = (voiceMail: HistoryItem) => async (dispatch: (action: BaseAction) => void) => {
  try {
    const response = await updateVoicemailSeenPlayed({
      itemId: voiceMail.itemId,
      isSeen: voiceMail.isSeen,
      isPlayed: voiceMail.isPlayed,
    });
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acUpdateHistoryItem(voiceMail));
    }
  } catch (error) {
    console.error(error);
  }
};

export { loadVoicemails, removeVoicemails, updateVoicemailState };

import ReduxActions from '../../constants/redux-actions';
import FeatureFlags from '../../models/feature-flags';

function acSetFeatureFlags(settings: FeatureFlags) {
  return {
    type: ReduxActions.SET_FEATURE_FLAGS,
    data: {
      featureFlags: settings,
    },
  };
}

export {
  acSetFeatureFlags,
};

import ReduxActions from '../../constants/redux-actions';
import { CommunicationLog, HistoryItem } from '../../models/communication-log';
import CommunicationLogType from '../../models/communication-log-type.enum';

function acSetVoicemails(communicationLog: CommunicationLog) {
  return {
    type: ReduxActions.LOAD_VOICE_MAILS,
    data: {
      totalVoiceMailCount: communicationLog.hintCount,
      availableVoiceMails:
        communicationLog?.historyItems?.filter((i) => i.itemType === CommunicationLogType.VOICEMAIL) ?? [],
    },
  };
}

function acUpdateHistoryItem(historyItem: HistoryItem) {
  return {
    type: ReduxActions.UPDATE_HISTORY_ITEM,
    data: {
      itemToUpdate: historyItem,
    },
  };
}

export { acSetVoicemails, acUpdateHistoryItem };

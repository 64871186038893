import ReduxActions from '../../constants/redux-actions';
import CallState from '../../models/call-state';
import { ErrorCodes } from '../../models/error-code';
import { BaseAction } from '../model/action';

const defaultState = {
  callStates: Array<CallState>(),
  callRecordingError: ErrorCodes.none,
  callTransferError: ErrorCodes.none,
};

const call = (state = defaultState, action: BaseAction) => {
  switch (action.type) {
    case ReduxActions.SET_CALL_STATES:
      return {
        ...state,
        callStates: action.data.callStates,
      };

    case ReduxActions.SET_CALL_RECORDING_ERROR:
      return {
        ...state,
        callRecordingError: action.data.callRecordingError,
      };

    case ReduxActions.SET_CALL_TRANSFER_ERROR:
      return {
        ...state,
        callTransferError: action.data.callTransferError,
      };

    default:
      return state;
  }
};

export default call;

declare global {
  interface Window {
    _env_: any;
  }
}

export const endpoints = {
  backEndBase: 'https://teams-connect-be-ahmed.loophole.site/api', //'https://teams.api.ymi.eu.ngrok.io/api',
  backEndNotificationBase: 'https://teams-connect-be-ahmed.loophole.site/notifications',
};

export const msTeamsSetting = {
  clientId: '40222b54-749a-475c-a3be-fc2dab78c353',
};

export const appVersion = 0 // window._env_.REACT_APP_VERSION;


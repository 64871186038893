import React from 'react';
import { Primitive } from '@fluentui/react-migration-v0-v9';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as voicemailAction from '../store/actions/voicemail-action';
import { voiceMail } from '../i18n/all';

interface PlayVoiceMessagePageState {
  voiceMailUrl: string;
  teamsUserId: string;
  itemId: string;
  error: string;
}

interface PlayVoiceMessagePageProps {
  actions: any;
}

export class PlayVoiceMessagePage extends React.Component<PlayVoiceMessagePageProps, PlayVoiceMessagePageState> {
  constructor(props: PlayVoiceMessagePageProps) {
    super(props);

    const params = new URLSearchParams(window.location.search);

    const teamsUserId = params.get('teamsUserId');
    const voiceMailUrl = params.get('voiceMailUrl')?.toString();

    if (!teamsUserId || !voiceMailUrl) {
      this.state = {
        voiceMailUrl: '',
        teamsUserId: '',
        itemId: '',
        error: voiceMail.voiceMailLoadError,
      };
    } else {
      const pathName = voiceMailUrl?.substring(0, voiceMailUrl?.indexOf('?'));
      const paths = pathName.split('/');
      this.state = {
        voiceMailUrl: voiceMailUrl,
        teamsUserId: teamsUserId?.toString(),
        itemId: paths[paths.length - 1],
        error: '',
      };
    }
  }

  render() {
    return (
      <Primitive
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        {!this.state.error && (
          <audio
            id={`vm-player-${this.state.itemId}`}
            controls
            onPlay={() => {
              this.props.actions.updateVoicemailState(this.state.teamsUserId, {
                itemId: this.state.itemId,
                isSeen: true,
                isPlayed: true,
              });
            }}
          >
            <source src={this.state.voiceMailUrl} type="audio/wav" />
          </audio>
        )}
        {!!this.state.error && <h2>{this.state.error}</h2>}
      </Primitive>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...voicemailAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayVoiceMessagePage);

import {
  getCallerAliases,
  getAcdAttendantGroup,
  getSupervisorGroup,
  updateAlias,
  getContactList,
  postLoginUserToAcdAttendantGroup,
  sendSms,
  postSupervisorLoginUserToAcdAttendantGroup,
  getUserRolesInfo,
  updateActiveUserRole,
} from '../../api/userApi';
import * as actionCreators from '../action-creators/user-data-action-creators';
import * as commonActionCreators from '../action-creators/common-action-creators';
import httpStatusCodes from '../../constants/http-status-codes';
import { BaseAction } from '../model/action';
import AcdAttendantGroup from '../../models/acd-attendant-group';
import CallerAlias from './../../models/caller-alias';
import Agent from '../../models/agent';
import AcdAttendantGroupAgents from './../../models/acd-attendant-group-agents';
import { UpdateCallerIdRequestModel } from '../../models/updateCallerIdRequestModel';
import { ErrorCodes } from '../../models/error-code';

const loadCallerAliases = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getCallerAliases();
    dispatch(actionCreators.acSetCallerAliases(response.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const updateCallerAliases = (aliases: CallerAlias[]) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const selectedAlias = aliases.find((alias) => alias.active);
    if (!selectedAlias) {
      return;
    }
    const data: UpdateCallerIdRequestModel = {
      type: selectedAlias.type,
      groupId: selectedAlias.groupId,
    };
    const response = await updateAlias(data);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetCallerAliases(aliases));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loadUserRolesInfo = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getUserRolesInfo();
    dispatch(actionCreators.acSetAvailableUserRoles(response.data.availableRoles));
    dispatch(actionCreators.acSetCurrentUserRole(response.data.activeRoleId));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const updateCurrentUserRole = (role: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await updateActiveUserRole(role);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetCurrentUserRole(role));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loadAcdAttendantGroup = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getAcdAttendantGroup();
    dispatch(actionCreators.acSetAcdAttendantGroups(response.data.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loadSupervisorGroup = (userObjectId: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getSupervisorGroup(userObjectId);
    dispatch(actionCreators.acSetSupervisorGroups(response.data.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loginUserToAcdAttendantGroup = (group: AcdAttendantGroup) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await postLoginUserToAcdAttendantGroup(group);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acRefreshAcdAttendantGroups(response.data.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const supervisorLoginUserToAcdAttendantGroup =
  (userObjectId: string, group: AcdAttendantGroupAgents, agent: Agent) =>
  async (dispatch: (action: BaseAction) => void) => {
    try {
      dispatch(commonActionCreators.acSetLoadingIndicator(true));
      const response = await postSupervisorLoginUserToAcdAttendantGroup(userObjectId, group, agent);
      if (response.status === httpStatusCodes.OK) {
        dispatch(actionCreators.acRefreshAgent(group, response.data.data));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(commonActionCreators.acSetLoadingIndicator(false));
    }
  };

const loadContactList = (userObjectId: string, query: string) => async (dispatch: (action: BaseAction) => void) => {
  try {
    const response = await getContactList(userObjectId, query);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetContactList(response.data.data));
    }
  } catch (error) {
    console.error(error);
  }
};

const sendSmsMessage =
  (text: string, numbers: string[], callerAlias: CallerAlias, callback?: (result: boolean) => {}) =>
  async (dispatch: (action: BaseAction) => void) => {
    if (!text || !numbers?.length || !callerAlias) {
      if (callback) {
        callback(false);
      }
      return;
    }
    try {
      dispatch(commonActionCreators.acSetLoadingIndicator(true));
      const response = await sendSms(text, numbers, callerAlias);
      if (response.status === httpStatusCodes.OK) {
        if (response.data.isError) {
          dispatch(actionCreators.acSetSendSmsError(response.data.errorCode));
        } else {
          dispatch(actionCreators.acSetSendSmsError(ErrorCodes.none));
          if (callback) {
            callback(response.data.data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(commonActionCreators.acSetLoadingIndicator(false));
    }
  };

const setSendSmsError = (error: string) => (dispatch: (action: BaseAction) => void) => {
  dispatch(actionCreators.acSetSendSmsError(error));
};

export {
  loadCallerAliases, //
  updateCallerAliases,
  loadUserRolesInfo,
  updateCurrentUserRole,
  loadAcdAttendantGroup,
  loadSupervisorGroup,
  loginUserToAcdAttendantGroup,
  supervisorLoginUserToAcdAttendantGroup,
  loadContactList,
  sendSmsMessage,
  setSendSmsError,
};

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VoicemailSettings from '../../models/voicemail-settings';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import { hasSmsWidgetAccessSelector, voicemailSettingsSelector } from '../../store/selectors/settings-data-selectors';
import * as settingsDataActions from '../../store/actions/settings-data-action';
import { Checkbox, Subtitle2Stronger } from '@fluentui/react-components';
import type { CheckboxOnChangeData } from '@fluentui/react-components';
import { voicemailSettings } from '../../i18n/all';
import * as microsoftTeams from '@microsoft/teams-js';

interface VoicemailSettingsProps {
  voicemailSettings: VoicemailSettings;
  msTeamsContext: microsoftTeams.app.Context | null;
  hasSmsWidgetAccess: boolean;
  actions: any;
}

interface VoicemailSettingsState {}

export class VoicemailSettingsComponent extends React.Component<
  VoicemailSettingsProps,
  VoicemailSettingsState
> {
  constructor(props: VoicemailSettingsProps) {
    super(props);
    this.props.actions.loadVoicemailSettings();
  }

  render() {
    const getUpdateCallback = (field: any, data: CheckboxOnChangeData) => {
      const newSettings: any = { ...this.props.voicemailSettings };
      newSettings[field] = data.checked;
      if (field === 'emailNotification' && !data.checked) {
        newSettings['fileAttached'] = false;
      }
      this.props.actions.saveVoicemailSettings(newSettings);
    };
    const settings = this.props.voicemailSettings;
    return (
      <div>
        <Subtitle2Stronger>{voicemailSettings.widgetTitle}</Subtitle2Stronger>
        <div>
          {this.props.hasSmsWidgetAccess && (
            <Checkbox
              checked={Boolean(settings?.smsNotification)}
              id="smsNotification"
              label={voicemailSettings.SMS}
              onChange={(ev, data) => getUpdateCallback('smsNotification', data)}
            />
          )}
        </div>
        <div>
          <Checkbox
            checked={Boolean(settings?.emailNotification)}
            id="emailNotification"
            label={voicemailSettings.Email}
            onChange={(ev, data) => getUpdateCallback('emailNotification', data)}
          />
          <Checkbox
            checked={Boolean(settings?.emailNotification && settings?.fileAttached)}
            id="fileAttached"
            disabled={!settings?.emailNotification}
            label={voicemailSettings.attachMessageToEmail}
            onChange={(ev, data) => getUpdateCallback('fileAttached', data)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  voicemailSettings: voicemailSettingsSelector(state),
  hasSmsWidgetAccess: hasSmsWidgetAccessSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoicemailSettingsComponent);

import axios from 'axios';
import { endpoints } from '../config/endpoints';
import ResponseModel from '../models/response-model';
import SmgToken from '../models/smg-token';
import * as storageHelper from '../helpers/storage-helper';

function isAuthenticated() {
  const url = `${endpoints.backEndBase}/auth/isAuthenticated`;

  const teamsSsoToken = storageHelper.getMsSsoToken();
  let config = {};

  if (teamsSsoToken) {
    config = {
      headers: {
        'Teams-Sso-Authorization': teamsSsoToken,
      },
    };
  }

  return axios.get<boolean>(url, config);
}

function authenticate() {
  const url = `${endpoints.backEndBase}/auth/authenticate`;

  const teamsSsoToken = storageHelper.getMsSsoToken();
  let config = {};

  const refreshToken = storageHelper.getSmgRefreshToken();
  if (teamsSsoToken && refreshToken) {
    config = {
      headers: {
        'Teams-Sso-Authorization': teamsSsoToken,
        'Smg-Refresh-Token': refreshToken,
      },
    };
  }

  return axios.post<boolean>(url, null, config);
}

function updateSmgTokens() {
  const url = `${endpoints.backEndBase}/auth/update-smg-tokens`;

  const refreshToken = storageHelper.getSmgRefreshToken();
  let config = {};

  if (refreshToken) {
    config = {
      headers: {
        'Smg-Refresh-Token': refreshToken,
      },
    };
  }

  return axios.post<boolean>(url, null, config);
}

function logout() {
  const url = `${endpoints.backEndBase}/auth/logout`;

  const teamsSsoToken = storageHelper.getMsSsoToken();
  let config = {};

  if (teamsSsoToken) {
    config = {
      headers: {
        'Teams-Sso-Authorization': teamsSsoToken,
      },
    };
  }

  return axios.post<ResponseModel>(url, null, config);
}

function getLoginUrl() {
  const url = `${storageHelper.getAuthBase()}/login/getloginurl/realm/${storageHelper.getRealm()}/client/${storageHelper.getClient()}`;
  return axios.post<{ loginUrl: string }>(url);
}

function getAccessToken(code: string) {
  const url = `${storageHelper.getAuthBase()}/login/getaccesstoken/realm/${storageHelper.getRealm()}/client/${storageHelper.getClient()}`;
  const body = {
    code,
    realm: storageHelper.getRealm(),
  };
  return axios.post<SmgToken>(url, body);
}

function refreshToken(accessToken: string, refreshToken: string) {
  const url = `${storageHelper.getAuthBase()}/login/refreshtoken/realm/${storageHelper.getRealm()}/client/${storageHelper.getClient()}`;
  const body = {
    refreshToken,
    jwt: accessToken,
  };
  return axios.post<SmgToken>(url, body);
}

function logoutFromSmg(refreshToken: string) {
  if (!refreshToken) {
    return;
  }

  const url = `${storageHelper.getAuthBase()}/login/logout/realm/${storageHelper.getRealm()}/client/${storageHelper.getClient()}`;
  const body = {
    refreshToken,
  };

  return axios.post(url, body);
}

export {
  isAuthenticated,
  authenticate,
  updateSmgTokens,
  logout,
  getLoginUrl,
  getAccessToken,
  refreshToken,
  logoutFromSmg,
};

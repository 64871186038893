import CallerAlias from '../models/caller-alias';
import { getStringOfAliasType } from '../constants/caller-aliases';

const formatAliasName = (alias: CallerAlias | undefined, hideNumber: boolean = false) => {
  if (!alias) {
    return '';
  }
  const aliasName = alias.name ? ` (${alias.name})` : '';
  return `${hideNumber ? '' : alias.number}
        ${getStringOfAliasType(alias.type)}
        ${aliasName}`;
};
export default formatAliasName;

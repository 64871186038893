import React from 'react';
import {
  Text,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHeaderCell,
  TableCellLayout,
  Checkbox,
  Button,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Spinner,
  TableHeader,
  Subtitle2Stronger,
  tokens,
} from '@fluentui/react-components';
import { Flex } from '@fluentui/react-migration-v0-v9';
import {
  GlassesOff16Filled,
  ArrowSync16Filled,
  Play16Filled,
  PresenceBlocked16Regular,
  CheckmarkCircle16Filled,
} from '@fluentui/react-icons';
import type { CheckboxProps } from '@fluentui/react-components';
import * as microsoftTeams from '@microsoft/teams-js';
import { cloneDeep } from 'lodash';

import * as voicemailAction from '../../store/actions/voicemail-action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import { HistoryItem } from '../../models/communication-log';
import { voiceMailsSelector } from '../../store/selectors/voicemail-selectors';
import { voiceMail } from '../../i18n/all';
import { isMobile } from 'react-device-detect';
import Moment from 'moment';
import * as storageHelper from '../../helpers/storage-helper';

interface Dictionary<T> {
  [Key: number]: T;
}

interface VoiceMailsProps {
  voiceMails: { availableVoiceMails: HistoryItem[]; totalVoiceMailCount: number };
  msTeamsContext: microsoftTeams.app.Context | null;
  actions: any;
}

interface VoiceMailsState {
  checkBoxesState: Dictionary<boolean>;
  loadedVoiceMails: Dictionary<VoicemailRecordingState | null>;
  listToDelete: any[];
}

interface VoicemailRecordingState {
  isPlaying: boolean;
  url: string;
}

export class VoiceMails extends React.Component<VoiceMailsProps, VoiceMailsState, CheckboxProps> {
  recordMinWidth = 400;

  constructor(props: VoiceMailsProps) {
    super(props);
    this.state = {
      checkBoxesState: {},
      loadedVoiceMails: {},
      listToDelete: [],
    };
    this.props.actions.loadVoicemails();
  }

  handleRowClick(voiceMail: HistoryItem) {
    var newListToDeleteState = this.state.checkBoxesState;
    newListToDeleteState[voiceMail.itemId] = !newListToDeleteState[voiceMail.itemId];
    let listOfIds = [];
    for (let key in newListToDeleteState) {
      if (newListToDeleteState[key]) {
        listOfIds.push(key);
      }
    }
    this.setState({ checkBoxesState: newListToDeleteState, listToDelete: listOfIds });
  }

  getCaller(historyItem: HistoryItem): string {
    const { displayName, number, contactId } = historyItem.remoteParty;
    if (number || (contactId && displayName)) {
      return `${displayName ?? ''} ${number ?? ''}`;
    }
    return 'Anonymous'; // not translated on purpose
  }

  onPlay(voiceMail: HistoryItem) {
    const itemToUpdate = cloneDeep(voiceMail);

    if (!itemToUpdate.isPlayed || !itemToUpdate.isSeen) {
      itemToUpdate.isPlayed = true;
      itemToUpdate.isSeen = true;
      this.props.actions.updateVoicemailState(itemToUpdate);
    }
  }

  componentDidUpdate() {
    if (this.props.msTeamsContext?.page?.subPageId !== 'voiceMail') {
      return;
    }
    const element = document.getElementById('voiceMail');

    element?.scrollIntoView({
      inline: 'start',
      behavior: 'smooth',
    });
  }

  getNormalHeaders(): any[] {
    return [
      {
        content: <TableHeaderCell key={'checked'} colSpan={!isMobile ? 1 : 2}></TableHeaderCell>,
      },
      {
        content: (
          <TableHeaderCell key={'ciId'} colSpan={!isMobile ? 3 : 3}>
            <TableCellLayout truncate style={{ fontWeight: tokens.fontWeightBold, fontSize: tokens.fontSizeBase200 }}>
              {voiceMail.caller}
            </TableCellLayout>
          </TableHeaderCell>
        ),
      },
      {
        content: (
          <TableHeaderCell key={'record'} colSpan={!isMobile ? 3 : 5}>
            <TableCellLayout truncate style={{ fontWeight: tokens.fontWeightBold, fontSize: tokens.fontSizeBase200 }}>
              {voiceMail.record}
            </TableCellLayout>
          </TableHeaderCell>
        ),
      },
      {
        content: !isMobile && (
          <TableHeaderCell key={'startTime'} colSpan={3}>
            <TableCellLayout truncate style={{ fontWeight: tokens.fontWeightBold, fontSize: tokens.fontSizeBase200 }}>
              {voiceMail.time}
            </TableCellLayout>
          </TableHeaderCell>
        ),
      },
      {
        content: !isMobile && (
          <TableHeaderCell key={'isSeen'} colSpan={1}>
            <TableCellLayout truncate style={{ fontWeight: tokens.fontWeightBold, fontSize: tokens.fontSizeBase200 }}>
              {voiceMail.seen}
            </TableCellLayout>
          </TableHeaderCell>
        ),
      },
      {
        content: (
          <TableHeaderCell key={'isPlayed'} colSpan={!isMobile ? 1 : 2}>
            <TableCellLayout truncate style={{ fontWeight: tokens.fontWeightBold, fontSize: tokens.fontSizeBase200 }}>
              {voiceMail.played}
            </TableCellLayout>
          </TableHeaderCell>
        ),
      },
    ];
  }

  getNormalRows(voiceMail: HistoryItem): any[] {
    return [
      {
        content: (
          <TableCell key={`${voiceMail.itemId}checked`} colSpan={!isMobile ? 1 : 2}>
            <TableCellLayout truncate>
              <Checkbox
                {...this.props}
                checked={!!this.state.checkBoxesState[voiceMail.itemId]}
                onChange={() => this.handleRowClick(voiceMail)}
              />
            </TableCellLayout>
          </TableCell>
        ),
      },
      {
        content: (
          <TableCell key={`${voiceMail.itemId}caller`} colSpan={!isMobile ? 3 : 3}>
            <TableCellLayout truncate>{this.getCaller(voiceMail)}</TableCellLayout>
          </TableCell>
        ),
      },
      {
        content: (
          <TableCell key={`${voiceMail.itemId}record`} colSpan={!isMobile ? 3 : 5}>
            <TableCellLayout truncate>
              {!!this.state.loadedVoiceMails[voiceMail.itemId] ? (
                <audio
                  autoPlay={true}
                  id={`vm-player-${voiceMail.callItemId}`}
                  controls
                  onPlay={() => {
                    this.onPlay(voiceMail);
                  }}
                >
                  <source src={this.state.loadedVoiceMails[voiceMail.itemId]?.url} type="audio/wav" />
                </audio>
              ) : (
                this.getPlayButton(voiceMail)
              )}
            </TableCellLayout>
          </TableCell>
        ),
      },
      {
        content: !isMobile && (
          <TableCell key={`${voiceMail.itemId}startTime`} colSpan={3}>
            <TableCellLayout truncate>{new Date(voiceMail.startTime).toLocaleString()}</TableCellLayout>
          </TableCell>
        ),
      },
      {
        content: !isMobile && (
          <TableCell key={`${voiceMail.itemId}isSeen`} colSpan={1}>
            <TableCellLayout truncate>
              {voiceMail.isSeen ? <CheckmarkCircle16Filled /> : <GlassesOff16Filled />}
            </TableCellLayout>
          </TableCell>
        ),
      },
      {
        content: (
          <TableCell key={`${voiceMail.itemId}isPlayed`} colSpan={!isMobile ? 1 : 2}>
            <TableCellLayout truncate>
              {voiceMail.isPlayed ? <CheckmarkCircle16Filled /> : <PresenceBlocked16Regular />}
            </TableCellLayout>
          </TableCell>
        ),
      },
    ];
  }

  fetchVoicemailRecord(voiceMail: HistoryItem) {
    this.setUrlForVoicemail(voiceMail.itemId, null);
    var requestObj = new Request(voiceMail.url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${storageHelper.getSmgAccessToken()}`,
      },
      referrerPolicy: 'no-referrer',
    });

    fetch(requestObj)
      .then((response) => response.blob())
      .then((blob) => {
        // convert our blob to a url that can be used by the audio tag
        const url = window.URL.createObjectURL(blob);
        this.setUrlForVoicemail(voiceMail.itemId, url);
      });
  }

  setUrlForVoicemail(itemId: number, url: string | null, callback?: () => void): void {
    // FIX!!!!
    // eslint-disable-next-line
    this.state.loadedVoiceMails[itemId] = url === null ? null : { isPlaying: true, url };
    this.setState(
      {
        loadedVoiceMails: this.state.loadedVoiceMails,
      },
      callback
    );
  }

  setPlayingStateForVoicemail(itemId: number, isPlaying: boolean, callback?: () => void): void {
    const voicemailState = this.state.loadedVoiceMails[itemId];
    if (voicemailState) {
      voicemailState.isPlaying = isPlaying;
    }
    this.setState({ loadedVoiceMails: this.state.loadedVoiceMails }, callback);
  }

  getPlayButton(voiceMail: HistoryItem) {
    return (
      <Flex gap="gap.small" space="between">
        <Button size='medium' icon={<Play16Filled />} title="Play" onClick={() => this.fetchVoicemailRecord(voiceMail)} />
        {this.state.loadedVoiceMails[voiceMail.itemId] === null && <Spinner size="small" />}
      </Flex>
    );
  }

  render() {
    const header = {
      key: 'header',
      items: isMobile ? this.getNormalHeaders() : this.getNormalHeaders(),
    };

    const rowsPlain = this.props.voiceMails?.availableVoiceMails?.map((voiceMail) => {
      return {
        key: voiceMail.itemId,
        style: { minHeight: '80px' },
        items: isMobile ? this.getNormalRows(voiceMail) : this.getNormalRows(voiceMail),
      };
    });

    const confirmHandle = () => {
      let listOfIds = [];
      for (let key in this.state.checkBoxesState) {
        if (this.state.checkBoxesState[key]) {
          listOfIds.push(key);
        }
      }
      if (listOfIds.length) {
        this.props.actions.removeVoicemails(listOfIds);
        this.setState({ checkBoxesState: {}, listToDelete: [] });
      }
    };

    return (
      <Flex column gap="gap.smaller" id="voiceMail" style={{ overflow: 'auto' }}>
        <Flex space="between" vAlign="end">
          <Subtitle2Stronger>{voiceMail.listHeader}</Subtitle2Stronger>
          <Button
            id="refresh"
            size="medium"
            icon={<ArrowSync16Filled />}
            onClick={() => {
              this.props.actions.loadVoicemails();
              this.setState({ checkBoxesState: {}, listToDelete: [], loadedVoiceMails: {} });
            }}
          />
        </Flex>
        {!!rowsPlain?.length && (
          <Table size="medium">
            <TableHeader>
              <TableRow key={header.key} style={{ borderBottomColor: tokens.colorNeutralBackground2Hover }}>
                {header.items.map((item) => item.content)}
              </TableRow>
            </TableHeader>
            <TableBody>
              {rowsPlain.map((item) => (
                <TableRow key={item.key} style={{ borderBottomColor: tokens.colorNeutralBackground2Hover }}>
                  {item.items.map((i) => i.content)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {!rowsPlain?.length && <Text>{voiceMail.noRecordsText}</Text>}
        {!!rowsPlain?.length && (
          <Flex gap="gap.small" space="between">
            <Dialog>
              <DialogTrigger disableButtonEnhancement>
                <Button disabled={!this.state.listToDelete.length}>{voiceMail.deleteSelected}</Button>
              </DialogTrigger>
              <DialogSurface>
                <DialogBody>
                  <DialogTitle>{voiceMail.popupHeader}</DialogTitle>
                  <DialogContent>
                    {voiceMail.deleteConfirmation1} {this.state.listToDelete.length} {voiceMail.deleteConfirmation2}
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary">{voiceMail.cancel}</Button>
                    </DialogTrigger>
                    <DialogTrigger disableButtonEnhancement>
                      <Button onClick={confirmHandle} appearance="primary">
                        {voiceMail.confirm}
                      </Button>
                    </DialogTrigger>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
            <Text>{`${voiceMail.showItems} ${this.props.voiceMails?.availableVoiceMails?.length} ${voiceMail.of} ${this.props.voiceMails?.totalVoiceMailCount}`}</Text>
          </Flex>
        )}
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  voiceMails: voiceMailsSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...voicemailAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoiceMails);

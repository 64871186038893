import { teamsLightTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-components';
import { TeamsThemes } from '../constants/constants';

export default class TeamsThemeHelper {
  static getTheme(themeStr: string | undefined) {
    themeStr = themeStr || '';
    switch (themeStr) {
      case TeamsThemes.dark:
        return teamsDarkTheme;
      case TeamsThemes.contrast:
        return teamsHighContrastTheme;
      case TeamsThemes.default:
      default:
        return teamsLightTheme;
    }
  }
}

import axios from 'axios';
import { endpoints } from '../config/endpoints';
import ResponseModel from '../models/response-model';
import CallerAlias from '../models/caller-alias';
import AcdAttendantGroup from '../models/acd-attendant-group';
import Contact from '../models/contact';
import AcdAttendantGroupAgents from './../models/acd-attendant-group-agents';
import Agent from '../models/agent';
import { UpdateCallerIdRequestModel } from '../models/updateCallerIdRequestModel';
import UserRolesInfoResponseModel from '../models/user-roles-info-response-model';

function getCallerAliases() {
  const url = `${endpoints.backEndBase}/user/aliases`;
  return axios.get<Array<CallerAlias>>(url);
}

function updateAlias(data: UpdateCallerIdRequestModel) {
  const url = `${endpoints.backEndBase}/user/aliases`;
  return axios.post(url, data);
}

function getUserRolesInfo() {
  const url = `${endpoints.backEndBase}/user/roles-info`;
  return axios.get<UserRolesInfoResponseModel>(url);
}

function updateActiveUserRole(role: string) {
  const url = `${endpoints.backEndBase}/user/role/${role}`;
  return axios.post(url);
}

function getAcdAttendantGroup() {
  const url = `${endpoints.backEndBase}/user/acdAttendantGroup`;
  return axios.get<ResponseModel<Array<AcdAttendantGroup>>>(url);
}

function getSupervisorGroup(userId: string) {
  const url = `${endpoints.backEndBase}/user/supervisorGroupAgents?userId=${userId}`;
  return axios.get<ResponseModel<Array<AcdAttendantGroupAgents>>>(url);
}

function postLoginUserToAcdAttendantGroup(group: AcdAttendantGroup) {
  const url = `${endpoints.backEndBase}/user/acdAttendantGroup/login`;
  return axios.post(url, group);
}

function postSupervisorLoginUserToAcdAttendantGroup(userId: string, group: AcdAttendantGroupAgents, agent: Agent) {
  const url = `${endpoints.backEndBase}/user/supervisorGroup/login?userId=${userId}`;
  const body = { group, agent };
  return axios.post(url, body);
}

function getContactList(userId: string, query: string) {
  const url = `${endpoints.backEndBase}/user/contacts/search?userId=${userId}&query=${query}`;
  return axios.get<ResponseModel<Array<Contact>>>(url);
}

function sendSms(text: string, numbers: string[], callerAlias: CallerAlias) {
  const url = `${endpoints.backEndBase}/user/sms/send`;
  const data = {
    text: text,
    numbers: numbers,
    callerAlias: callerAlias,
  };
  return axios.post<ResponseModel<boolean>>(url, data);
}

export {
  getCallerAliases, //
  updateAlias,
  getUserRolesInfo,
  updateActiveUserRole,
  getAcdAttendantGroup,
  getSupervisorGroup,
  postLoginUserToAcdAttendantGroup,
  postSupervisorLoginUserToAcdAttendantGroup,
  getContactList,
  sendSms,
};

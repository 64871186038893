import React, { CSSProperties } from 'react';
import { Spinner } from '@fluentui/react-components';

const loaderBackgroundStyle: CSSProperties = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgb(255, 255, 255, 0.5)',
  zIndex: 8000,
};

const loaderContainer: CSSProperties = {
  position: 'relative',
  top: '50%',
};

export default class BlockingLoader extends React.Component {
  render() {
    return (
      <div style={loaderBackgroundStyle}>
        <div style={loaderContainer}>
          <Spinner />
        </div>
      </div>
    );
  }
}
